import React from "react"

import Layout from "components/Layout/en"
import Product from "components/Layout/Product"
import productImg from "./product.jpg"
import productIcon from "./LogisticsControl.png"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Automate your logistics processes with the efficient empty container
        tracking system from MoonVision - through visual object recognition on
        vehicles (forklifts) for complete image documentation from goods receipt
        to dispatch. MoonVision has the highest detection accuracy thanks to
        extensive experience".
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Automated detection of container types</li>
        <li>Benefit from consistent quality and reduced throughput time</li>
        <li>Detection and measurement by camera & software</li>
        <li>Highest accuracy & comfort through artificial intelligence</li>
        <li>Visual output or forwarding to subsequent systems</li>
      </ul>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="LogisticsControl"
        title="Pallet type detection in real time"
        subtitle="Pallet type detection in real time"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automated component testing",
          "Consistently high quality",
          "Shortened lead time",
          "Find out now",
        ]}
      >
        <React.Fragment>
          <p>
            MoonVision LogisticsControl was especially designed for the
            identification of containers. The automatic creation of snapshots,
            clustering and various methods of Object Mining quickly get an
            overview of all possible containers in your logistics warehouses.
          </p>
          <p>
            When the forklift driver takes over containers, they are
            automatically identified by a camera mounted on the forklift truck,
            Subsequently the item can can be transported to the designated
            storage location. The recognition of the containers (type and
            quantity= can be transferred to the production line or during
            delivery to a transporation trucks.
          </p>
          <p>
            Depending on the application, the detectioncan be delivered both for
            new (undamaged) and used containers, which already show signs of
            wear and tear.
          </p>
          <h3>Product overview</h3>
          <ul>
            <li>Automated container recognition</li>
            <li>Reduced transfer times</li>
            <li>Reduced throughput times</li>
          </ul>
          <h3>Available where you need it</h3>
          <p>
            MoonVision LogisticsControl can use all existing camera systems as
            data sources - which meet the Genicam standard. We are also happy to
            advise you on the choice of the right system. The verification can
            be done in real time via our online platform. Alternatively, we can
            also install the system on site. In both cases you get access to the
            MoonVision Toolbox and can make adjustments e.g. for parameter
            adaptation and personalization at any time.
          </p>
          <h3>Easy to use</h3>
          <p>
            Due to the software design, the optimization and implementation of
            MoonVision LogisticsControl can be realized with little effort at
            your site. Already after checking a few image/video data you can be
            sure about the quality, further image data can be added at a later
            time. All data is collected and stored centrally and can be accessed
            by you at any time.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
